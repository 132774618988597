.button {
  font-weight: bold;
  font-size: 1em;
  margin-top: 1em;
  border-width: 8px;
  @include breakpoint(large) {
    border-width: 13px;
  }
  &:hover {
    color: $white;
  }
}

.bg-black .button {
  color:$white;
  border-color:$white;
  background-color: transparent;
  &:hover {
    color: $black;
    background-color: $white;
  }
}


.cookieconsent .button {
  border-width: 4px;
  @include breakpoint(large) {
    border-width: 7px;
  }
}
