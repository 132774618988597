.logo {
  padding: rem-calc(20);
  a {
    display: inline-block;
    img {
      width: 200px;
      height: auto; //immer width und height in PX angeben (IE)
    }
  }
}
