@font-face {
  font-family: "Mont";
  src: url("../fonts/5624616/43bc1d39-e6ab-4d76-88b2-397cf5c92526.woff2")
      format("woff2"),
    url("../fonts/5624616/7896e26a-a97e-4a04-ad72-3324c29977cf.woff")
      format("woff");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "Mont";
  src: url("../fonts/5853019/70562c10-d8ab-42a5-888d-5c0e73ac7245.woff2")
      format("woff2"),
    url("../fonts/5853019/ea1751cf-feea-4af3-a294-c334399f3dac.woff")
      format("woff");
  font-weight: bold;
  font-display: swap;
}

//global font-size (small, medium, large)
main {
  @include font-size(1.125, 1.375);
}

a:focus {
  outline: none;
}

h2 {
  @include font-size(2, 4.625);
  margin-bottom: 0.4em;
  line-height: 1.2162;
  font-weight: bold;
}

h3 {
  @include font-size(1.7, 3.75);
  line-height: 1.25;
  font-weight: bold;
  margin-bottom: 1em;
}


.txtwegbeschrieb {
  h1 {
    margin-top:1em;
    margin-bottom:0.15em;
  }
}

