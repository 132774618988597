form {
    textarea, input {
        margin-top:1.5em;
        display:block;
        width: 100%;
        
        border:13px solid $white;
        border-radius: 0;
        background-color: transparent;
        padding:0.7em 1em;

        font: inherit;
        color:inherit;
    }
    textarea {
        height:25em;
        resize: none;
    }
}

.kontaktform {
    .ctrsend {
        margin-top:3em;
        text-align: right;
    }
    button {
        margin:0;
    }
}