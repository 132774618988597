ul.nolist {
  list-style-type:none;
  margin:0;
  padding:0;
  li {
      a {
          color:inherit;
      }
  }
}

ul:not(.menu):not(.form):not(.nolist) {
  margin-left: rem-calc(14);
  padding-left: 0;
  list-style-type: none;
  li {
    &:before {
      content: "– ";
      margin-left: rem-calc(-14);
      margin-right: 0;
      padding-right: rem-calc(3);
    }
  }
}
