.timelapse-embed {
  padding: 0;
  border: none;
  padding-bottom: 66.6%;
  width: 100%;
  max-width: 100%;
  margin: 0;
  display: block;
  overflow: hidden;
  // @include breakpoint(1565) {
  //   padding-bottom: 65%;
  // }
  // @include breakpoint(1585) {
  //   padding-bottom: 61.6%;
  // }
}

.video-border {
  max-width: 1280px;
  margin: 2em auto 1em auto;
  border: 8px solid $black;
  @include breakpoint(large) {
      border-width: 13px;
  }
}
