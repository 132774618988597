@mixin border-no-color {
  border-width: $border-width;
  border-style: solid;

  @include breakpoint(large) {
    border-width: $border-width-large;
  }
}

@mixin outline-no-color {
  outline-width: $border-width;
  outline-style: solid;

  @include breakpoint(large) {
    outline-width: $border-width-large;
  }
}
