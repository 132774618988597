.hero {
  position: relative;
  .bgimg {
    width: 100%;
    height: 60vh;
    @include breakpoint(600) {
      height: 100vh;
      height: 100dvh;
    }
    object-fit: cover;
  }

  .txt-over-img {
    position: absolute;
    z-index: 2;
    bottom: 1.4054em;
    max-width: 9.3243em;
    color: $white;

    pointer-events: none;

    @include breakpoint (small down) {
      //bottom:2.5em;
    }

    &.align-left {
      left: rem-calc(20);
      @include breakpoint(large) {
        left: 1.8919em;
      }
    }
    &.align-right {
      right: rem-calc(20);
      @include breakpoint(large) {
        right: 1.8919em;
      }
      text-align: right;
    }
  }

  .scrolldownarrow {
    cursor: pointer;
    position:absolute;
    z-index: 2;
    bottom:1.5em;
    left:0;
    right:0;
    margin-left: auto;
    margin-right: auto;
    width:fit-content;
    border:4px solid $black;
    background-color: white;

    img {
      width:rem-calc(25);
      display: block;
      padding:rem-calc(4);
    }

    @include breakpoint(small down) {
      display:none;
    }
  }

}
