.newspopup {
    display:none;
    position: fixed;
    left:0;
    top:0;
    right:0;
    bottom:0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index:100;

    overflow-y: auto;
    overscroll-behavior: contain;

    --borderwidth:13px;
    --closebuttonpadding:2em;

    @include breakpoint(medium down) {
        --borderwidth:8px;
        --closebuttonpadding:1em;
    }

    .dlg {
        border:var(--borderwidth) solid $black;
        background-color: $white;
        margin:3em auto;
        width:rem-calc(1340);
        max-width: 95vw;
    }

    .ctrclosebutton {
        padding:var(--closebuttonpadding);
        display:flex;
        justify-content: flex-end;
    }
    .closebutton {
        border:4px solid $black;
        padding:0.15em;
        cursor: pointer;
    }
    @include breakpoint(large) {
        .ctrcontent {
            display:grid;
            grid-template-columns:repeat(2, 1fr);
            gap:rem-calc(80);
        }
    }
    .colimages {
        position: relative;

        .imagelist {
            display:flex;
            flex-direction: column;
            gap:1em;
        }
    }
    @include breakpoint(large) {
        .coltext {
            margin-right:5em;
        }
    }
    .coltext {
        h3 {
            @include font-size(1.4, 2.0625);
            line-height: 1.5151;
            font-weight:400;
            margin-bottom:0.5em;
        }
        h2 {
            @include font-size(1.6, 3.4375);
            line-height: 1.2727;
            font-weight:700;
            margin-bottom: 0.5em;
        }

        @include breakpoint(medium down) {
            padding:1em;
        }
    }

    .ctrsideinfos {
        position: absolute;
        right:rem-calc(55);
        top:rem-calc(-50);
        left:rem-calc(55);

        @include breakpoint(medium down) {
            right:var(--closebuttonpadding);
            left:var(--closebuttonpadding);
            top:var(--closebuttonpadding);
        }

        .date {
            @include font-size(1.5, 2.5625);
            line-height: 1.3171;
            font-weight: 700;
            //letter-spacing: 0.02em;
            padding:rem-calc(10) rem-calc(20);
        }
        .title {
            //@include font-size(1.6, 3.9375);
            @include font-size(1.6, 3.5);
            line-height: 1.3333;
            font-weight: 700;
            //letter-spacing: 0.02em;
            margin-top:0.2em;
            padding:rem-calc(10) rem-calc(20);
        }
        .date, .title {
            border:var(--borderwidth) solid $black;
            background-color: $white;
            margin-left: auto;
            width: fit-content;
        }
    }

    .ctrslider {
        display:none;
    }
    @include breakpoint (medium down) {
        .ctrslider {
            display:block;
        }
        .colimages {
            display:none;
        }
    }

    .ctrslider .ctrpagination {
        width:auto;
        right:var(--closebuttonpadding);
    }
}