//Swiper vohanden in: .bl-swiper und in Newspopup

//Stile fuer alle Swiper
.swiper {
    position: relative;
    .swiper-slide {
        img {
            display: block;
            width: 100%;
        }
    }
    .ctrpagination {
        position:absolute;
        left:0;
        width:100%;
        bottom:1em;
        z-index:1;
    }
    .swiper-pagination-bullet {
        border-radius: 0;
        background-color: rgba(255,255,255,0.8);  
        width:28px;
    }
    .swiper-pagination-bullet-active {
        background-color: $white;
    }    
    .paginationbuttons {
        text-align: right;
    }
}
