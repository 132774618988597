// html.sr {
//   .fade-in-interval, .fade-in-news, .fade-in-bottom {
//     visibility: hidden;
//   }
// }

.bg-black {
  background-color: $black;
  color: $white;
}

.border {
  @include border-no-color;
}

.bg-white .border {
  border-color: $black;
}

.bg-white .border {
  border-color: $black;
}


.fontSize-74 {
  @include font-size(1.6, 4.625);
  line-height: 1.2162;
  font-weight: bold;
}

.fontSize-45,
.fontSize-45 p {
  @include font-size(1.5, 2.8125);
  line-height: 1.3333;
}

.fontSize-33 {
  @include font-size(1.4, 2.0625);
  line-height: 1.5151;
}

.fontSize-32 {
  @include font-size(1.3, 2);
  line-height: 1.3125;
}

img.fullw {
  display:block;
  width:100%;
}

img.fullh {
  min-height:100%;

  object-fit: cover;
  object-position: center center;
}

@include breakpoint(medium down) {
  .mediumdown-mt-1 {
    margin-top:1em;
  }
}

@include breakpoint(large) {
  .large-pl-1 {
    padding-left:1em;
  }
  .large-pr-1 {
    padding-right:1em;
  }
}
