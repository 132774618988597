.off-canvas {
  //padding-top: 3em;
  max-width: 100%;
  //width:100%;

  padding:1.5rem;
  padding-top:6rem;

  .menuitems {
    ul {
      //reset ul
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    a {
      font-size:rem-calc(24);
      margin-bottom:0.6em;
      display: block;
      width:fit-content;
    }
  }
}


.menuicon {
  aspect-ratio: 1;
  height: rem-calc(40);
  box-sizing: border-box;

  border:4px solid $black;
  color:$black;
  background-color: $white;
  cursor: pointer;

  background-image:url('/assets/app/images/menuicon.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size:auto rem-calc(20);

  &.isopen {
      background-image:url('/assets/app/images/menuicon_close.svg');
  }
  /*
  display:flex;
  justify-content: center;
  align-items: center;

  img {
      display:block;
      height:rem-calc(20);
  }
  */
}
.menuicon {
  position:fixed;
  right:1.5rem;
  top:1.5rem;
  z-index:80;
}
@include breakpoint(large) {
  .menuicon {
      display:none;
  }
}

