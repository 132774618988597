.slider {
  padding-bottom: 6rem;
  position: relative;
  @include breakpoint(medium) {
    padding-bottom: 4rem;
  }
  .slides-wrapper {
    display: flex;
    //overflow: auto;
    overflow: hidden;
    scrollbar-width: none;
    cursor: grab;
    gap: 5rem;

    @include breakpoint(large) {
      gap: 16rem;
    }

    .slide {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
      @include breakpoint(medium) {
        gap: 3rem;
      }
      @include breakpoint(large) {
        flex-direction: row;
        gap: 8rem;
      }

      .img-container {
        position: relative;
        margin: 2rem 0 1rem 0;
        height: 35vh;
        width: 100vw;
        @include breakpoint(medium) {
          width: max-content;
        }
        @include breakpoint(large) {
          height: 40vw;
        }

        .inclined-line {
          position: absolute;
          left: -5rem;
          transform: rotate(45deg);
          top: 50%;
          width: 8rem;
          height: $border-width;
          background-color: $black;

          @include breakpoint(large) {
            height: $border-width-large;
            width: 16rem;
            left: -10rem;
          }
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          @include breakpoint(medium) {
            object-fit: contain;
          }
        }

        .txt-container-over-img {
          position: absolute;
          right: 2rem;
          top: -2rem;
          display: flex;
          gap: 1rem;
          flex-direction: column;
          align-items: flex-end;
          @include breakpoint(large) {
            right: 6rem;
          }

          .date,
          .place {
            padding: 1rem 2rem;
            line-height: 1;
            background-color: $white;
          }

          .date {
            @include font-size(1.5, 2.52625);
            font-weight: bold;
          }

          .place {
            @include font-size(2, 3.9375);
            font-weight: bold;
          }
        }
      }

      .txt-container {
        max-width: 90vw;
        padding-left: rem-calc(20);
        font-size: rem-calc(14);
        hyphens: auto;
        @include breakpoint(medium) {
          font-size: inherit;
        }
        @include breakpoint(large) {
          margin-top: 8rem;
          width: 30vw;
        }
        h3 {
          hyphens: auto;
          margin-bottom: 0.7rem;
          line-height: 1.2;
          @include breakpoint(medium) {
            margin-bottom: 1.1rem;
          }
        }
        .surtitle {
          margin-bottom: 1em;
        }
      }
    }
  }

  .arrows-container {
    position: absolute;
    width: 100vw;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    top: 200px;
    @include breakpoint(medium) {
      top: auto;
      bottom: 0;
      padding: 0 2rem;
    }
    @include breakpoint(large) {
      padding: 0 8rem;
    }

    .arrow-prev,
    .arrow-next {
      $color-disabled: rgba($black, 0.6);
      flex-shrink: 1;

      &:disabled {
        border-color: $color-disabled;

        .svg-icon svg {
          fill: $color-disabled;
        }
        &:hover {
          border-color: $color-disabled;
          background-color: $white;
          .svg-icon svg {
            fill: $color-disabled;
          }
        }
      }
    }

    .arrow-prev {
      transform: rotate(180deg);
    }
  }
}

.arrow-prev,
.arrow-next {
  background: $white;
  @include border-no-color;
  border-color: $black;
  padding: 0.4rem;
  cursor: pointer;
  &:hover {
    background: $black;

    .svg-icon svg {
      fill: $white;
    }
  }

  .svg-icon {
    height: 3rem;
    width: 3rem;

    @include breakpoint(large) {
      height: 5rem;
      width: 5rem;
    }

    svg {
      bottom: 0;
      fill: $black;
    }
  }
}
