.txt-left p {
  @include breakpoint(large) {
    margin-top: 1.333em;
  }
}

#counter {
  @include font-size(7, 12.5625);
  line-height: 1;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);

  @include breakpoint(medium down) {
    margin-top: 0.2em;
  }

  > div {
    display: flex;
  }

  .inner-container {
    padding: 0.1em;
    display: inline-flex;
    @include border-no-color;
  }

  .title-container {
    justify-content: right;
  }

  .months-container {
    justify-content: right;

    .inner-container {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      @include breakpoint(medium down) {
        max-width: 250px;
      }

      .number {
        margin-top: 0.12em;
      }
    }
  }

  .weeks-container {
    justify-content: left;
    align-items: flex-end;

    .inner-container {
      align-items: flex-end;
      width: 100%;

      @include breakpoint(medium down) {
        max-width: 250px;
      }
    }
  }

  .remaining-days-container {
    justify-content: right;
  }

  .days-label-container {
    justify-content: left;

    .inner-container {
      align-items: flex-end;
      width: 75%;

      @include breakpoint(medium down) {
        max-width: 180px;
      }
    }
  }

  .label {
    @include font-size(1, 2.0625);
    line-height: 1.4848;
    font-weight: bold;
  }

  .number {
    line-height: 0.72;
  }
}
