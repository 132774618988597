.grid-container {
  padding-left: rem-calc(20);
  padding-right: rem-calc(20);
}

section,
footer {
  &:not(.hero), .innerpadding {
    padding: 3rem 0;

    @include breakpoint(large) {
      padding: 6.5rem 0;
    }
  }

  &.nopadding {
    padding:0;
  }
}

.street-container {
  @include breakpoint(medium down) {
    margin-top: 2rem;
  }

  .name {
    @include font-size(0.8, 1.1325);
    display: block;
    font-weight: bold;
  }

  .number-container {
    display: inline-block;
    padding: 2rem 1rem;
    @include font-size(6, 12.5625);
    line-height: 0.72;
    margin: 0.1em 0 0.3em;
  }
}

.partner-container p {
  @include font-size(1.4, 2.8125);
  font-weight: bold;
  line-height: 1.0889 !important;
  margin-bottom: 0.4em;
}
