.cardboxes {
    @include breakpoint(large) {
        display:grid;
        grid-template-columns:repeat(3, 1fr);
        gap:rem-calc(60) rem-calc(20);

        .item {
            min-width: 0;
        }
    }
    @include breakpoint(medium down) {
        display:flex;
        flex-direction: column;
        gap:rem-calc(60);
    }
}

.cardboxes,
.cardbox {
    .item {
        //reset for <a> tag
        display: block;
        color:inherit;
    }

    .ovimg {
        display:block;
        width: 100%;
    }

    .info {
        padding:rem-calc(30);

        .text {
            a {
                text-decoration: underline;
                color:inherit;
            }
        }
    } 

    h3 {
        margin:0.1em 0;
    }

    .readmorelink {
        text-decoration: underline;
        font-weight:700;
    }
}

.morebutton-center {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: fit-content;
    margin-top:rem-calc(50);
}

.bl-mietenovlist {
    margin-top:2em;
}

[data-opennewspopup] {
    cursor: pointer;
}
