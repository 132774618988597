.menuitems {
    a {
        border:4px solid $black;
        padding-left:0.4em;
        padding-right:0.4em;
        color:$black;
        background-color: $white;
        font-weight: 700;

        color:inherit;
    }

    a:hover,
    li.active a {
        background-color: $black;
        color:$white;
    }
}

.desktopmenu {
    position: fixed;
    z-index: 10;
    left:0;
    width:100%;
    top:1.5em;

    $menuheight:rem-calc(40);

    pointer-events: none;
    .menuitems {
        pointer-events: initial;
    }

    .ctrmenucontent, ul {
        display:flex;
        justify-content: flex-end;
        gap:rem-calc(20);
    }

    a {
        display:flex;
        align-items: center;

        height:$menuheight;
        box-sizing: border-box;
    }

    .menuitems {
        transition: opacity 0.2s;
    }
    /*
    @include breakpoint(large) {
        .menuitems {
            pointer-events: initial;
        }
    }
    @include breakpoint(medium down) {
        .menuitems:not(.visible) {
            opacity:0;
        }
    }
    */
}

@include breakpoint(medium down) {
    .desktopmenu {
        display:none;
    }
}

/*
@include breakpoint(small down) {
    .ctrmenucontent, ul {
        flex-direction: column;
        align-items: flex-end;
    }
    
    .menuicon {
        order:-1;
    }
}
*/
