.kmieterlist {
    $lh:1.4;
    h3 {
        @include font-size(1.4, 2);
        font-weight: 700;
        line-height: 1.3125;
        margin-bottom:1.3125em;
    }
    h4 {
        font-size:inherit;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom:$lh*1em;
    }
    h5 {
        font-size: inherit;
        font-weight: 700;
        margin-bottom:0;
        margin-top:$lh*1em;
    }
    p {
        margin-bottom: $lh*1em;
        line-height: $lh;
    }
    a {
        text-decoration: underline;
        color:inherit;
    }
    a[href*="@"] {
        /*email links*/
        text-decoration: none;
    }
    p + h4 {
        margin-top:2*$lh*1em;
    }

    @include breakpoint(large) {
        .cols {
            display:grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }
}