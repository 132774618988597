footer {
  background-color: $black;
  color: $white;

  a {
    color: $white;
    text-decoration: underline;

    &.nounderline {
      text-decoration: none;
    }

    /*
    &.mail {
      @include font-size(1, 2);
      font-weight: bold;
      margin-top: 2em;
      padding: 0.5em 0.8em;
      display: inline-block;

      &:hover {
        background-color: $white;
        color: $black;
      }
    }
    */
  }

  /*
  .dataSecurity-container {
    @include breakpoint(medium) {
      float: right;
    }
  }
  */

  .footerlogo {
    img {
      display:block;
      height:7.3em;
    }
  }

  .socialicons {
    display:flex;
    gap:rem-calc(8);

    margin-top:0.35em;
    margin-bottom: 0.35em;

    .icon {
      height:2.3em;
      display: block;
    }
    
    a {
      display: block;
    }
  }

  @include breakpoint(large) {
    .footerinner {
      display:flex;
      width: 100%;
      justify-content: space-between;
      gap:rem-calc(20);
    }
  }
  @include breakpoint(medium down) {
    .footerinner {
      display: flex;
      flex-direction: column;
      gap:1.5em;
    }
  }
}
