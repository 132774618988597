.stockmieter-container {
    .stockwerk {
        text-transform: uppercase;
    }
    .mieter {
        @include font-size(1.5, 2.8125);
        line-height: 1.3333;
        margin-bottom:0.75em;
    }
    [data-plan] {
        cursor: pointer;
        &:hover {
            font-weight: 700;
        }
        &.active {
            font-weight: 700;
        }
    }
}

.firmenpopup {
    display:none;
}

@include breakpoint (medium down) {
    .firmenpopup {
        margin-top:2em;
    }
}
